/* eslint-disable */
import * as d3 from "d3";
import { Config } from "./models";

export class CTWGaugeBar {

  // fields & constructor
  private svg: any;
  private resizeHandler: any = null;
  private config: Config;
  private initDone: boolean = false;
  private dim: any = { w: 0, h: 0 };
  private margin: any = { x: 0, t: 14, b: 0 };
  private r: number = 0;
  private value: number = 0;
  constructor(container: string, config: Config) {
    this.svg = d3.select(`svg#${container}`);
    this.config = config;
    this.init();
  }
  public destroy() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  // public methods
  public update(value?: number) {

    if (!this.initDone) return;

    // update values
    if (value !== null && value !== undefined) this.value = value;

    const fullWidth = this.dim.w - this.margin.x * 2;
    this.svg.select('rect.bar').transition().duration(500)
      .attr('width', fullWidth * this.value / 100)
      .attr('fill', this.getColor());
    this.svg.select('text.title').text(this.config.title);
    this.svg.select('text.value').text(this.value + (this.config.unit ? ' ' + this.config.unit : ''));

  }

  // private methods
  private init() {

    // set svg styles
    this.svg.attr('class', 'ct-widget gauge-bar full-width');
    this.resizeHandler = () => { this.resize(); };

    // svg structure
    const gBar = this.svg.append('g').attr('class', 'bar');
    gBar.append('rect').attr('class', 'bg');
    gBar.append('rect').attr('class', 'bar');
    this.svg.append('text').attr('class', 'title');
    this.svg.append('text').attr('class', 'value');

    this.initDone = true;
    this.resize();
    window.addEventListener('resize', this.resizeHandler);

  }
  private resize() {

    if (!this.initDone) return;
    const bounds = this.svg.node().getBoundingClientRect();
    // get svg dimensions
    // this.dim.w = parseInt(this.svg.style('width'), 10);
    // this.dim.h = parseInt(this.svg.style('height'), 10);
    this.dim.w = bounds.width;
    this.dim.h = bounds.height;
    // size components
    this.svg.select('rect.bg').attr('width', this.dim.w - this.margin.x * 2).attr('height', this.dim.h - this.margin.t);
    this.svg.select('rect.bar').attr('height', this.dim.h - this.margin.t);

    // position components
    this.svg.select('g.bar').attr('transform', `translate(${this.margin.x}, ${this.margin.t})`);
    this.svg.select('text.title').attr('y', 12);
    this.svg.select('text.value').attr('x', this.dim.w - this.margin.x).attr('y', 12);

    this.update();

  }

  private getColor() {
    if (this.state === 1) return this.config.colorW || 'darkorange';
    if (this.state === 2 || this.state === 3) return this.config.colorE || 'red';
    return this.config.color;
  }

  // properties
  get errorUnderflow() { return this.value < this.config.min; }
  get errorOverflow() { return this.value > this.config.max; }
  get warningMin() { return this.config.minW && this.value < this.config.minW; }
  get warningMax() { return this.config.maxW && this.value < this.config.maxW; }
  get errorMin() { return this.config.minE && this.value < this.config.minE; }
  get errorMax() { return this.config.maxE && this.value < this.config.maxE; }
  get state() {
    if (this.errorUnderflow || this.errorOverflow) return 3;
    if (this.errorMin || this.errorMax) return 2;
    if (this.warningMin || this.warningMax) return 1;
    return 0;
  }
  get info() {
    if (this.errorUnderflow) return 'UNDERFLOW';
    if (this.errorMin) return 'ERROR: LOW';
    if (this.warningMin) return 'WARNING: LOW';
    if (this.errorOverflow) return 'OVERFLOW';
    if (this.errorMax) return 'ERROR: HIGH';
    if (this.warningMax) return 'WARNING: HIGH';
    return '';
  }

}